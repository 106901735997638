import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import CarouselControl from './carouselControl'
import CarouselSlide from './carouselSlide'
import DynamicImage from '../Images/dynamicImage'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
    },
    slider: {
        overflow: 'hidden',
    },
    slides: {
        display: 'flex',
        margin: 0,
        padding: 0,
        scrollBehavior: 'smooth',
        transition: 'all 350ms ease-out',
    },
}))

const Carousel = ({ items }) => {

    const classes = useStyles()

    const [position, setPosition] = useState(0)

    const slides = useRef(null);

    const setTransform = (animate = true) => {

        if (animate) {
            slides.current.style['transition'] = 'all 250ms ease-out'
        } else {
            slides.current.style['transition'] = ''
        }
        slides.current.style['transform'] = 'translate3d(' + (-position * slides.current.offsetWidth) + 'px,0,0)'
    }

    const next = () => setPosition(Math.min(position + 1, items.length - 1))

    const prev = () => setPosition(Math.max(position - 1, 0))

    useEffect(() => {
        setTransform()
    }, [position])

    useEffect(() => {
        setTransform(false)
    }, [])

    return (
        <div className={classes.root}>

            <CarouselControl left hidden={position === 0} disabled={position === 0} direction='left' onClick={() => prev()} />
            <CarouselControl right light secondary hidden={position === items.length - 1} disabled={position === items.length - 1} direction='right' onClick={() => next()} />

            <div className={classes.slider} >
                <div className={classes.slides} ref={slides} >
                    {
                        items && items.length ? <>
                            {items.map((item, i) => <CarouselSlide key={'image_' + item + '_' + i}><DynamicImage filename={item} /></CarouselSlide>)}
                        </> : <div></div>
                    }
                </div>
            </div>

        </div>
    )
}

export default Carousel
import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import EmailIcon from '@material-ui/icons/Email'
import { Container, Button, Typography } from '@material-ui/core'

const Home = () => {

    const useStyles = makeStyles(theme => ({
        root: {
            display: 'flex',
            WebkitBoxPack: 'center',
            justifyContent: 'center',
            WebkitBoxAlign: 'center',
            flexDirection: 'column',
            alignItems: 'flex-start',
            minHeight: '100vh',
            padding: '100px 0 30px'
        },
        subtitle: {
            maxWidth: '700px',
        },
        proffessions: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(10),
            '& > h3': {
                marginBottom: theme.spacing(3)
            },
            '& > ul': {
                listStyle: 'none',
                margin: '0',
                padding: '0',
                '& li': {
                    padding: `${theme.spacing(1 / 2)}px 0 0 ${theme.spacing(2)}px`,
                    position: 'relative',
                    fontWeight: '300',
                    '&:before': {
                        content: '\'▹\'',
                        position: 'absolute',
                        left: '0px',
                        color: theme.palette.secondary.main,
                    }
                }
            },
        },
        super: {
            fontFamily: 'Share Tech Mono',
            fontSize: '1rem'
        }
    }))

    const StyledHeading = withStyles({
        root: {
            padding: '1.2rem 0 0.7rem 0'
        }

    })(Typography)

    const StyledButton = withStyles(theme => ({
        root: {
            textTransform: 'none',
            fontWeight: '300',
            fontFamily: 'Share Tech Mono',
            border: `1px solid ${theme.palette.secondary.main}`
        }
    }))(Button)

    const StyledSubHeading = withStyles(theme => ({
        root: {
            marginBottom: theme.spacing(5),
        }
    }))(Typography)

    const classes = useStyles()

    return (
        <section className={classes.root} id="home">
            <Container maxWidth="md">
                <div className={ classes.super }>Hi, my name is</div>
                <StyledHeading
                    variant="h1"
                    component="h1"
                    color="secondary">
                    Jóhannes Freyr Þorleifsson
                </StyledHeading>
                <StyledSubHeading variant="h3" component="h2">I design and build smart things for the web.</StyledSubHeading>
                <Typography className={classes.subtitle}>
                    I’m a software developer / graphic designer based in Reykjavik, Iceland, specializing in building and designing websites, applications and everything between.
                </Typography>
                <div className={classes.proffessions}>
                    <Typography variant="h3" component="h3">I do</Typography>
                    <ul>
                        <li>Web design and UX</li>
                        <li>Wireframing and prototyping</li>
                        <li>Front-end development</li>
                        <li>General software development</li>
                    </ul>
                </div>

                <StyledButton
                    variant="outlined"
                    color="secondary"
                    size="large"
                    startIcon={<EmailIcon />}>
                    Get In Touch
                </StyledButton>
            </Container>
        </section>
    )
}

export default Home
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { makeStyles } from '@material-ui/core/styles'

import Img from "gatsby-image"


const HoverImage = ({ filename, active, alt, opacity = '1' }) => (
	<StaticQuery
		query={graphql`
        query {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  sizes(maxWidth: 300) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
        }
      `}

		render={(data) => {

			const image = data.images.edges.find(n => {
				return n.node.relativePath.includes(filename);
			});
			if (!image) { return null; }

			const imageSizes = image.node.childImageSharp.sizes;


			const classes = makeStyles(theme => ({
				root: {
					position: 'absolute',
					left: 0,
					right: 0,
					opacity: active ? opacity : 0,
					transition: 'all 350ms ease-in-out 100ms',
					borderRadius: '3px',
					overflow: 'hidden',
				}
			}))()

			return (
				<div className={classes.root}>
					<Img
						alt={alt}
						sizes={imageSizes}
					/>
				</div>

			)
		}}
	/>
)

export default HoverImage
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        position: 'relative',
    },
    first: {
        textTransform: "uppercase",
        fontWeight: 600,
    },
}))

const ProjectsHeader = ({ type }) => {
    
    const classes = useStyles()

    return (
        <div className={ classes.root }>
            <Typography variant="h4" className={ classes.heading } >
                {
                    type === 'web' ? <><div className={ classes.first }>Web</div> <div>development</div></> : <div className={ classes.first }>Branding</div>
                }
            </Typography>
        </div>
    )
}

export default ProjectsHeader
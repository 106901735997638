import React from 'react'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { OpenInNew } from '@material-ui/icons'
import { Box, Typography, Grid } from '@material-ui/core'

import TechStack from '../TechStack/techStack'
import Carousel from '../Carousel/carousel'

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(4)
    },
    description: {
        height: '100%'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyItems: 'space-around'
    },
    header: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: theme.spacing(3)
    }
}))

const StyledHeading = withStyles(theme => ({
    root: {
        fontWeight: 500,
        margin: `0 ${theme.spacing(2)}px 0 0`,
        
    }
}))(Typography)

const Project = ({ type, title, description, images, techStackItems }) => {

    const classes = useStyles()
    const theme = useTheme()
    const matchesBpUpMd = useMediaQuery(theme.breakpoints.up('md'))
    
    return (
        <div className={classes.root}>
            <Grid container spacing={matchesBpUpMd ? 4 : 0}>
                <Grid item xs={12} md={4}>
                    <Box m={matchesBpUpMd ? 2 : 0 } className={classes.description}>
                        <header className={ classes.header }>
                            <StyledHeading variant="h5" >{title}</StyledHeading>
                            { type === 'web' ? <>
                                <a href={'http://www.' + title } target="_blank" rel="noreferrer">
                                    <OpenInNew color="secondary"/>
                                </a>
                            </> : <></>}
                        </header>
                        <div className={ classes.content }>
                            <Typography paragraph>
                                {description}
                            </Typography>
                            { techStackItems && techStackItems.length && <TechStack items={ techStackItems } repeat={2}/> }
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Box ml={4} mr={matchesBpUpMd ? 0 : 4}>
                        <Carousel items={ images } />
                    </Box>
                    
                </Grid>
            </Grid>

        </div>
    )
}

export default Project
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({

    root: {
        flexShrink: 0,
        width: '100%',
    }

})

const CarouselSlide = ({ children }) => {

    const classes = useStyles()

    return <div className={ classes.root }>{ children }</div>
    
}

export default CarouselSlide
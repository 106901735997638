import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const TechStack = ({ items, repeat = 3 }) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            //flexGrow: 1,
            display: 'grid',
            gridTemplateColumns: `repeat(2, minmax(140px, 200px))`,
            [theme.breakpoints.up('md')]: {
                gridTemplateColumns: `repeat(${repeat}, minmax(140px, 200px))`,
            },
            overflow: 'hidden',
            padding: 0,
            margin: `${theme.spacing(3)}px 0`,
            listStyle: 'none',
        },
        item: {
            fontFamily: 'Share Tech Mono',
            fontSize: 13,
            marginBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            position: 'relative',
            opacity: '0.75',
            '&:before': {
                content: '\'▹\'',
                position: 'absolute',
                left: '0px',
                color: theme.palette.secondary.main,
                fontFamily: 'Saira',
            }
        }
    
    }))

    const classes = useStyles()

    return (
        <ul className={ classes.root }>
            {
                items.map(item => (<li key={ item } className={ classes.item } >{ item }</li> ))
            }            
        </ul>
    )
}

export default TechStack
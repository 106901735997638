import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'


const StyledArrowBack = withStyles(theme => ({
    root: {
        top: '50%',
        left: `-${theme.spacing(4)}px`,
        position: 'absolute',
        zIndex: 10,
        marginTop: `-${theme.spacing(1)}px`,
        cursor: 'pointer',
    },
    colorDisabled: {
        color: 'rgba(255,255,255,0.4)'
    }
}))(ArrowBackIos)

const StyledArrowForward = withStyles(theme => ({
    root: {
        top: '50%',
        right: `-${theme.spacing(4)}px`,
        position: 'absolute',
        zIndex: 10,
        marginTop: `-${theme.spacing(1)}px`,
        cursor: 'pointer'
    },
    colorDisabled: {
        color: 'rgba(255,255,255,0.4)'
    }
}))(ArrowForwardIos)

const CarouselControl = ({ direction, disabled, onClick }) => {

    return direction === 'left' ? <StyledArrowBack onClick={ onClick } color={ disabled ? 'disabled' : 'inherit' } /> : <StyledArrowForward onClick={ onClick } color={ disabled ? 'disabled' : 'inherit' } />
            
}

export default CarouselControl
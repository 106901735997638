import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'

import Navigation from '../Navigation/navigation'
import useScrollPosition from '../Navigation/useScrollPosition'

const useStyles = makeStyles(theme => ({

	root: {
		display: 'flex',
		WebkitBoxPack: 'justify',
		WebkitBoxAlign: 'center',
		alignItems: 'center',
		position: 'fixed',
		top: '0px',
		left: '0px',
		backgroundColor: theme.palette.primary.main,
		zIndex: '11',
		width: '100%',
		height: '70px',
		transform: 'translateY(0px)',
		filter: 'none !important',
		pointerEvents: 'auto !important',
		userSelect: 'auto !important',
		padding: '0px 50px',
		boxShadow: 'rgba(2, 12, 27, 0.7) 0px 10px 30px -10px',
		transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
		visibility: 'visible',
	},
	container: {
		width: '100%',
	},
	scrolling: {
		boxShadow: 'rgba(2, 12, 27, 0.7) 0px 10px 30px -10px',
		height: '70px',
		visibility: 'hidden',
		transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
		transform: 'translate(0, -100%)'
	},
	top: {
		boxShadow: 'none',
		height: '100px'
	},
}))

const Header = ({ siteTitle }) => {

	const [hideOnScroll, setHideOnScroll] = useState(false)
	const [isTop, setIsTop] = useState(true)

	useScrollPosition(({ prevPos, currPos }) => {
		const isShow = currPos.y < prevPos.y
		if (isShow !== hideOnScroll) setHideOnScroll(isShow)

		setIsTop(currPos.y > -50)

	}, [hideOnScroll], false, false, 300)

	const classes = useStyles()

	return (
		<Hidden smDown implementation="css">
			<header className={[classes.root, hideOnScroll ? classes.scrolling : '', isTop ? classes.top : ''].join(' ')}>
				<div className={classes.container}><Navigation /></div>
			</header>
		</Hidden>
	)
}
Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Typography from '@material-ui/core/Typography'


const SectionHeader = ({ title, variant, useIndexPrefix = true, line = true, centered = false, index }) => {

    const theme = useTheme()

    const matchBpMdUp = useMediaQuery(theme.breakpoints.up('md'))
    
    const useStyles = makeStyles((theme) => ({
        root: {
            display: centered ? 'block' : 'flex',
            alignItems: 'baseline',
            marginBottom: theme.spacing(6),
            marginTop: theme.spacing(4),
            '&::after': {
                content:'" "',
                display: line && matchBpMdUp ? 'block' : 'none',
                width: '300px',
                height: '1px',
                backgroundColor: 'rgba(255,255,255,0.35)',
                position: "relative",
                top: '-12px',
                marginLeft: theme.spacing(4)
            }
        },
        prefix: {
            color: theme.palette.secondary.main,
            fontFamily: 'Share Tech Mono',
            fontSize: '1.8rem'
        },
        heading: {
            fontWeight: 300,
        }
    }))

    const classes = useStyles()

    return (
        <div className={ classes.root }>
            <Typography variant={ variant } className={ classes.heading } >
                { useIndexPrefix ? <><span className={ classes.prefix }>{'0' + (index + 1) + '. '}</span>{ title }</> : title }
            </Typography>
        </div>
    )
}

export default SectionHeader
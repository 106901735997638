import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

export const HamburgerMenu = ({ open, sidebar, onClick }) => {

    const useStyles = makeStyles( theme => ({
        hamburger: {
            display: 'flex',
            position: sidebar ? 'fixed' : 'relative',
            top: 0,
            right: 0,
            color: theme.palette.secondary.main,
            WebkitBoxPack: 'center',
            justifyContent: 'center',
            WebkitBoxAlign: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            transitionTimingFunction: 'linear',
            transitionDuration: '0.15s',
            transitionProperty: 'opacity, filter',
            textTransform: 'none',
            backgroundColor: 'transparent',
            overflow: 'visible',
            marginRight: sidebar ? 0 : `-${theme.spacing(2)}px`,
            padding: theme.spacing(2),
            borderWidth: '0px',
            borderStyle: 'initial',
            borderColor: 'initial',
            borderImage: 'initial'
        },
        box: {
            position: 'relative',
            display: 'inline-block',
            width: theme.spacing(4),
            height: theme.spacing(3)
        },
        inner: {
            '&.innhUU, &.cuvuVE': {
                position: 'absolute',
                width: theme.spacing(4),
                height: '2px',
                top: '50%',
                left: '0px',
                right: '0px',
                transitionDuration: '0.22s',
                transitionProperty: 'transform'
            },
            '&.innhUU': {
                backgroundColor: theme.palette.secondary.main,
                transitionDelay: '0s',
                transform: 'rotate(0deg)',
                transitionTimingFunction: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
                borderRadius: '3px',
            },
            '&.cuvuVE': {
                backgroundColor: theme.palette.secondary.secondary,
                transitionDelay: '0.12s',
                transform: 'rotate(225deg)',
                transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
                borderRadius: '3px'
            },
            '&.innhUU:before, &.innhUU:after': {
                content: '\'\'',
                display: 'block',
                backgroundColor: theme.palette.secondary.main,
                position: 'absolute',
                left: 'auto',
                right: '0px',
                width: theme.spacing(4),
                height: '2px',
                transitionTimingFunction: 'ease',
                transitionDuration: '0.15s',
                transitionProperty: 'transform',
                borderRadius: '4px'
            },
            '&.innhUU:before': {
                width: '120%',
                top: '-10px',
                opacity: '1',
                transition: 'top 0.1s ease-in 0.25s, opacity 0.1s ease-in 0s'
            },
            '&.innhUU:after': {
                width: '80%',
                bottom: '-10px',
                transform: 'rotate(0deg)',
                transition: 'bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s'
            },
            '&.cuvuVE:before, &.cuvuVE:after': {
                content: '\'\'',
                display: 'block',
                backgroundColor: theme.palette.primary.main,
                position: 'absolute',
                left: 'auto',
                right: '0px',
                width: theme.spacing(4),
                height: '2px',
                transitionTimingFunction: 'ease',
                transitionDuration: '0.15s',
                transitionProperty: 'transform',
                borderRadius: '4px'
            },
            '&.cuvuVE:before': {
                width: '100%',
                top: '0px',
                opacity: '1',
                transition: 'top 0.1s ease-out 0s, opacity 0.1s ease-out 0.12s'
            },
            '&.cuvuVE:after': {
                width: '100%',
                bottom: '0px',
                transform: 'rotate(-90deg)',
                transition: 'bottom 0.1s ease-out 0s, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s'
            },
            '&.is-open': {
                transitionDelay: '0.12s',
                transform: 'rotate(225deg)',
                transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
                borderRadius: '3px'
            }
        }
    }))

    const classes = useStyles()

    return (
        <div className={classes.hamburger} onClick={onClick} >
            <div className={classes.box}>
                <div className={[classes.inner, open ? 'cuvuVE' : 'innhUU'].join(' ')}></div>
            </div>
        </div>
    )
}

export default HamburgerMenu
import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import Header from '../components/Header/header'
import ResponsiveDrawer from '../components/Navigation/responsiveDrawer'
import Home from '../components/Sections/home'
import About from '../components/Sections/about'
import Experience from '../components/Sections/experience'
import Projects from '../components/Sections/projects'
import Contact from '../components/Sections/contact'


export default function Index() {

	const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

	return (
		<>
			<Header siteTitle={data.site.siteMetadata.title} />
			<ResponsiveDrawer>
				<Home />
				<About />
				<Experience />
				<Projects />
				<Contact />
			</ResponsiveDrawer>
		</>
	)
}

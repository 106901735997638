import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Container, Typography } from '@material-ui/core'

import SectionHeader from './sectionHeader'
import HoverImage from '../Images/hoverImage'
import TechStack from '../TechStack/techStack'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
        WebkitBoxPack: "center",
        justifyContent: "center",
        WebkitBoxAlign: "center",
        flexDirection: "column",
        alignItems: "flex-start",
        minHeight: "70vh",
        backgroundColor: theme.palette.primary.dark,
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
    figure: {
        position: 'relative',
        maxWidth: 280,
        width: 280,
        minHeight: 320,
        margin: `0 auto ${theme.spacing(2)}px auto`,
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
            float: 'right',
            margin: `0 0 ${theme.spacing(4)}px 0`,
        },
        '&:before': {
            content: '" "',
            width: '100%',
            height: '320px',
            border: '1px solid #fff',
            position: 'absolute',
            left: '-20px',
            top: '-18px',
            borderRadius: '3px',
            transition: 'all 350ms ease-in-out',
        },
        '&:hover': {
            '&:before': {
                left: '-15px',
                top: '-13px'
            }
        }
    },
    backgroundFill: {
        position: 'absolute',
        width: '100%',
        height: '320px',
        backgroundColor: theme.palette.primary.dark
    }
}))

const About = () => {

    const classes = useStyles()

    const [isHover, setIsHover] = useState(false)

    return (
        <section className={classes.root} id="about">

            <Container maxWidth="md">
                <SectionHeader title="About" variant="h3" index={0} useIndexPrefix />

                <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                        <Typography paragraph>
                            Hello I’m Jóhannes!
                            A software developer, graphic designer, and a family man.
                        </Typography>
                        <Typography paragraph>
                            I have more than 10 year experience in software development and have worked as a front-end developer for the most part but I also enjoy working with back-end development. Recently I have been using ReactJS and Angular for  front-end related projects. I’m very familiar with the Microsoft .NET platform and have some knowledge of Azure cloud services.
                            I also have worked quite a bit with graphic design, web design and user interface. I find it very useful to have education in the design field when working in software development.
                        </Typography>
                        <Typography paragraph>
                            Here are few technologies that I have been working with recently:
                        </Typography>
                        <TechStack items={['React', 'Angular', 'Redux', 'HTML/CSS', 'C#', 'MS SQL', 'NodeJS', 'Wordpress', 'Gatsby']} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <figure className={classes.figure} onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onFocus={() => void 0}>
                            <div className={ classes.backgroundFill } />
                            <HoverImage filename="profile.jpg" active={!isHover}  />
                            <HoverImage filename="profile_color.jpg" active={isHover} opacity={ '0.85' } />
                        </figure>
                    </Grid>
                </Grid>

            </Container>


        </section>
    )
}

export default About

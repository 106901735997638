import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


const DynamicImage = (props) => (
    <StaticQuery
        query={graphql`
        query {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  sizes(maxWidth: 800) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
        }
      `}

        render={(data) => {
            const image = data.images.edges.find(n => {
                return n.node.relativePath.includes(props.filename)
            })
            if (!image) { return null; }

            const imageSizes = image.node.childImageSharp.sizes
            return (
                <Img
                    alt={props.alt}
                    sizes={imageSizes}
                    loading="eager"
                    style={ { borderRadius: 4 } }
                />
            );
        }}
    />
)

export default DynamicImage
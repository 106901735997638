import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Link, Events } from "react-scroll"
import { Container, Divider, Drawer, Hidden, List, ListItem, ListItemText, Typography} from '@material-ui/core'
import { useStaticQuery, graphql } from "gatsby"

import useScrollPosition from '../Navigation/useScrollPosition'
import HamburgerMenu from './hamburgerMenu'

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    navContent: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    scrolling: {
        boxShadow: 'rgba(2, 12, 27, 0.7) 0px 10px 30px -10px !important',
		visibility: 'hidden !important',
		transform: 'translate(0, -100%) !important'
    },
    top: {
		boxShadow: 'none !important',
    },
    navLink: {
        '& > .active': {
            fontWeight: 'bold',
        }
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.secondary.main
    },
    content: {
        flexGrow: 1,
        width: '100%',
        maxWidth: '100%',
    },
    hiddenMobile: {
        width: '0%'
    }
    
}))

const StyledContainer = withStyles(theme => ({
    root: {
        zIndex: '1000',
        backgroundColor: theme.palette.primary.main,
        position: 'fixed',
        visibility: 'visible',
        boxShadow: '0px 10px 30px -10px rgba(2, 12, 27, 0.7)',
        transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
    }
}))(Container)

let navItems = []

const ResponsiveDrawer = (props) => {
    const { window, children } = props
    const classes = useStyles()

    const [mobileOpen, setMobileOpen] = useState(false)
    const [hideOnScroll, setHideOnScroll] = useState(false)
    const [isTop, setIsTop] = useState(true)

    const { allNavigationJson } = useStaticQuery(
        graphql`
            query {
                allNavigationJson {
                    edges {
                        node {
                            id
                            title
                        }
                    }
                }
            }
        `
      )

    if(navItems.length === 0) {
        for(let i = 1; i < allNavigationJson.edges.length; i++) {
            navItems.push(allNavigationJson.edges[i])
        }
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    useScrollPosition(({ prevPos, currPos }) => {

        const scrollThreshold = 100
		const isShow = (currPos.y < prevPos.y) && Math.abs(currPos.y) > scrollThreshold
		if (isShow !== hideOnScroll) setHideOnScroll(isShow)

		setIsTop(currPos.y > -scrollThreshold)

    }, [hideOnScroll], false, false, 300)
    
    useEffect(() => {
        Events.scrollEvent.register('end', () => setMobileOpen(false))
        return () => {
            Events.scrollEvent.remove('end')
        }
    },[])

    const menuList = (
        <aside>
            <div className={classes.toolbar} />
            <HamburgerMenu color="primary" onClick={handleDrawerToggle} aria-label="open-drawer" open={ mobileOpen } sidebar />
            <Divider />
            <List>
                {navItems.map((navItem, index) => (
                    <ListItem button key={navItem.node.id} >
                        {
                            <Link activeClass="active" to={ navItem.node.id } spy={true} smooth={true} offset={-70} duration={500} className={classes.navLink}>
                                <ListItemText primary={'0' + (index + 1) + '. ' + navItem.node.title} />
                            </Link>
                        }
                    </ListItem>
                ))}
            </List>
        </aside>
    )

    const container = window !== undefined ? () => window().document.body : undefined

    return (
        <div className={classes.root}>
            <Hidden mdUp implementation="css" className={ classes.hiddenMobile } >
                <StyledContainer maxWidth="md" className={[hideOnScroll ? classes.scrolling : '', isTop ? classes.top : ''].join(' ')}>
                    <div className={ classes.navContent } >
                        <Typography variant="h6" noWrap>Jóhannes Freyr</Typography>
                        <HamburgerMenu color="primary" onClick={handleDrawerToggle} aria-label="open-drawer" open={ mobileOpen } />
                    </div>

                    <nav className={classes.drawer} aria-label="mobile nav" >
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={'right'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{ keepMounted: true }}
                        >
                            {menuList}
                        </Drawer>
                    </nav>
                </StyledContainer>
                
            </Hidden>
            <main className={classes.content}>
                {children}
            </main>
        </div>
    );
}

export default ResponsiveDrawer

import React, { useState } from 'react'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { Container, Box, Tabs, Tab, Grid } from '@material-ui/core'

import SectionHeader from './sectionHeader'
import Project from '../Project/project'
import ProjectsHeader from '../Project/projectsHeader'

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        backgroundColor: theme.palette.primary.dark,

        minHeight: '100vh',
        flexGrow: 1,
        display: "flex",
        WebkitBoxPack: "center",
        justifyContent: "center",
        WebkitBoxAlign: "center",
        flexDirection: "column",
        alignItems: "flex-start",

        [theme.breakpoints.up('md')]: {
            padding: "300px 0px",
        },
    },
    pillHeader: {
        marginBottom: theme.spacing(4),
    },
    tabsContainer: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 1,
            display: 'flex',
            backgroundColor: theme.palette.primary.dark,
        }
    },
    tabs: {
        [theme.breakpoints.up('md')]: {
            backgroundColor: theme.palette.primary.dark,
            borderRight: `1px solid ${theme.palette.secondary.main}`,
        }
    },
}))

const StyledTabs = withStyles(theme => ({
    root: {
        marginBottom: theme.spacing(4),
    },
    scroller: {
        [theme.breakpoints.down('md')]: {
            border: '1px solid rgba(255,255,255,0.25)',
            borderRadius: '3px'
        }
    },
    centered: {
        justifyContent: 'space-around'
    },
    indicator: {
        [theme.breakpoints.up('md')]: {
            right: 0,
        }
    }
}))(Tabs)

const StyledTab = withStyles(theme => ({
    root: {
        
        fontFamily: 'Share Tech Mono',
        textTransform: 'none',
        
        [theme.breakpoints.up('md')]: {
            marginLeft: -theme.spacing(3),
            textAlign: 'left',
        }
    },
    wrapper: {
        [theme.breakpoints.up('md')]: {
            alignItems: 'flex-start',
            paddingLeft: theme.spacing(2),
        }
    },
    selected: {
        color: theme.palette.secondary.main
    },
    
}))(Tab)

const StyledGrid = withStyles(theme => ({
    root: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(6),
        justifyContent: 'space-between',
        '& > div': {
            flexBasis: 'auto'
        }
    }
}))(Grid)

/* const StyledToggleButtonGroup = withStyles(theme => ({
    root: {
        color: theme.palette.secondary.main
    }
}))(ToggleButtonGroup)

const StyledToggleButton = withStyles(theme => ({
    root: {
        color: 'rgba(255,255,255,0.5)',
        borderColor: '#fff'
    },
    selected: {
        color: `${theme.palette.secondary.main} !important`
    }
}))(ToggleButton) */

const a11yProps = (index) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props
    const theme = useTheme()
    const matchesBpUpMd = useMediaQuery(theme.breakpoints.up('md'))
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other} >
            {value === index && (
                <Box p={matchesBpUpMd ? 4 : 0 } pr={0}>
                    {children}
                </Box>
            )}
        </div>
    )
}

const Projects = () => {

    const [value, setValue] = useState(0)
    const [projectType, setProjectType] = useState('web')

    const classes = useStyles()
    const theme = useTheme()
    const matchesBpUpMd = useMediaQuery(theme.breakpoints.up('md'))
    const matchesBpDownMd = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <section className={classes.root} id="projects">
            <Container maxWidth="lg">
                <div className={classes.pillHeader}>
                    <SectionHeader title="Recent Projects" variant="h3" index={2} useIndexPrefix />

                    <StyledGrid container spacing={matchesBpUpMd ? 4 : 0}>
                        <Grid item md={12}>
                            <ProjectsHeader type={projectType} />
                        </Grid>
                        {/* <Grid item md={6}>
                            <StyledToggleButtonGroup aria-label="Select type of project" size="small" orientation={ matchesBpUpMd ? 'horizontal' : 'vertical' }>
                                <StyledToggleButton value="left" onClick={() => setProjectType('web')} selected={projectType === 'web'}>Web development</StyledToggleButton>
                                <StyledToggleButton value="right" onClick={() => setProjectType('branding')} selected={projectType === 'branding'}>Branding</StyledToggleButton>
                            </StyledToggleButtonGroup>
                        </Grid> */}

                    </StyledGrid>
                </div>
                <div className={classes.tabsContainer}>
                    {
                        projectType === 'web' ? <>
                            <StyledTabs
                                orientation={ matchesBpUpMd ? "vertical" : "horizontal" }
                                value={value}
                                onChange={(event, newValue) => setValue(newValue)}
                                aria-label="Recent Works"
                                className={classes.tabs}
                                centered={ matchesBpDownMd } >
                                <StyledTab label="payday.is" {...a11yProps(0)} />
                                <StyledTab label="iuslaw.is" {...a11yProps(1)} />
                                <StyledTab label="meniga.is" {...a11yProps(2)} />
                            </StyledTabs>
                            <TabPanel value={value} index={0}>
                                <Project
                                    type="web"
                                    title="payday.is" 
                                    description="Design for the landing page including logo design. Coding and implementation. Custom theme design and coding on the admin template that was bought. Back-end programming and logic"
                                    techStackItems={['Figma', 'Phototshop', 'JQuery', 'Less', 'C#', 'ASP.NET', 'Bootstrap']} 
                                    images={ ['payday_safari_light_1.png', 'payday_safari_light_2.png', 'payday_safari_light_3.png'] }/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Project 
                                    type="web"
                                    title="iuslaw.is" 
                                    description="Design and implementation of new website for IUS law firm. The website was implemented with Angular and Wordpress API." 
                                    techStackItems={['Photoshop', 'Angular', 'SSR', 'Bootstrap', 'Wordpress']} 
                                    images={['ius_safari_light_theme.png', 'ius_ios_light.png']}/>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Project 
                                    type="web"
                                    title="meniga.is" 
                                    description="A project that I was assigned to when working at Meniga ehf. The task was to implement a new look for the meniga.is landing page and make it more mobile friendly." 
                                    techStackItems={['HTML', 'Less', 'Bootstrap', 'React']}
                                    images={ ['meniga_safari_light_1.png', 'meniga_safari_light_2.png'] } />
                            </TabPanel>
                        </> : <>
                                <div>Coming soon.. stay tuned</div>
                            </>
                    }

                </div>
            </Container>
        </section>
    )
}

export default Projects
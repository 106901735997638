import React, { useState } from 'react'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'
import { Container, Tabs, Tab, Box, Typography, useMediaQuery } from '@material-ui/core'

import SectionHeader from './sectionHeader'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
        WebkitBoxPack: "center",
        justifyContent: "center",
        WebkitBoxAlign: "center",
        flexDirection: "column",
        alignItems: "flex-start",
        minHeight: '70vh',
    },
    tabsContainer: {
        backgroundColor: theme.palette.primary.main,
        padding: 0,
        width: '100%',
        maxWidth: '100%',
        [theme.breakpoints.up('md')]: {
            flexGrow: 1,
            display: 'flex',
        }
    },
    tabs: {

        [theme.breakpoints.up('md')]: {
            borderLeft: `1px solid rgba(255,255,255,0.35)`,
        }
    },
    company: {
        color: theme.palette.secondary.main,
        fontWeight: 500,
    },
    heading: {
        fontWeight: 300,
        fontSize: '1.25rem',
        letterSpacing: '.3px',
        marginBottom: theme.spacing(1)
    },
    period: {
        fontFamily: 'Share Tech Mono',
        marginBottom: theme.spacing(2),
        opacity: '0.65'
    }
}))

const StyledTabs = withStyles(theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(2),
            border: '1px solid rgba(255,255,255,0.25)',
            borderRadius: '3px'
        },
    },
    centered: {
        justifyContent: 'space-around'
    },
    indicator: {
        [theme.breakpoints.up('md')]: {
            left: 0
        }
    }
}))(Tabs)

const StyledTab = withStyles(theme => ({
    root: {
        fontFamily: 'Share Tech Mono',
        textTransform: 'none',

        [theme.breakpoints.up('md')]: {
            textAlign: 'left'
        }
    },
    wrapper: {
        [theme.breakpoints.up('md')]: {
            alignItems: 'flex-start',
            paddingLeft: theme.spacing(2),
        }
    },
    selected: {
        color: theme.palette.secondary.main
    }
}))(Tab)

const StyledBox = withStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        '& > ul': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
            '& > li': {
                padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
                position: 'relative',
                fontWeight: '300',
                '&:before': {
                    content: '\'▹\'',
                    position: 'absolute',
                    left: '0px',
                    color: theme.palette.secondary.main,
                }
            }
        }
    }
}))(Box)

const TabPanel = (props) => {
    const { children, value, index, ...other } = props
    const theme = useTheme()
    const matchesBpUpMd = useMediaQuery(theme.breakpoints.up('md'))
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other} >
            {value === index && (
                <StyledBox p={matchesBpUpMd ? 3 : 0}>
                    {children}
                </StyledBox>
            )}
        </div>
    )
}

const a11yProps = (index) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Experience = () => {

    const classes = useStyles();
    const [value, setValue] = useState(0)

    const theme = useTheme()
    const matchesBpUpMd = useMediaQuery(theme.breakpoints.up('md'))

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (

        <section className={classes.root} id="experience">
            <Container maxWidth="md">
                <SectionHeader title="Experience" variant="h3" index={1} useIndexPrefix />

                <div className={classes.tabsContainer}>
                    <StyledTabs
                        orientation={matchesBpUpMd ? 'vertical' : 'horizontal'}
                        //variant={ matchesBpUpMd ? 'standard' : 'scrollable' }
                        scrollButtons="auto"
                        value={value}
                        onChange={handleChange}
                        aria-label="Working experience"
                        className={classes.tabs}
                        centered={!matchesBpUpMd}
                    >
                        <StyledTab label="Meniga" {...a11yProps(0)} />
                        <StyledTab label="Divot" {...a11yProps(1)} />
                        <StyledTab label="VenuePoint" {...a11yProps(2)} />
                        <StyledTab label="Advania" {...a11yProps(3)} />
                    </StyledTabs>

                    <TabPanel value={value} index={0}>
                        <Typography variant="h5" className={classes.heading}>Software developer <span className={classes.company}>@ Meniga</span></Typography>
                        <Typography variant="subtitle2" className={classes.period}>March 2018 - Present</Typography>
                        <ul>
                            <li>Frontend development in ReactJS and ASP.NET MVC</li>
                            <li>Backend development (.NET C#)</li>
                            <li>Front end testing (JEST)</li>
                            <li>Build pipelines and releases</li>
                        </ul>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Typography variant="h5" className={classes.heading}>Software developer <span className={classes.company}>@ Divot</span></Typography>
                        <Typography variant="subtitle2" className={classes.period}>January 2016 - March 2018</Typography>
                        <ul>
                            <li>Frontend development</li>
                            <li>Backend development (.NET C#)</li>
                            <li>Database programming (Microsoft SQL server)</li>
                            <li>Occasionally design based tasks and prototyping</li>
                        </ul>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Typography variant="h5" className={classes.heading}>Software developer <span className={classes.company}>@ VenuePoint/Divot</span></Typography>
                        <Typography variant="subtitle2" className={classes.period}>July 2014 - December 2015</Typography>
                        <ul>
                            <li>Frontend development</li>
                            <li>Backend development (.NET C#)</li>
                            <li>Occasionally design based tasks and prototyping</li>
                            <li>Tech support</li>
                        </ul>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Typography variant="h5" className={classes.heading}>Web developer <span className={classes.company}>@ Advania</span></Typography>
                        <Typography variant="subtitle2" className={classes.period}>June 2012 - June 2014</Typography>
                        <ul>
                            <li>Web development</li>
                            <li>Creating new web domains.</li>
                            <li>Design and prototyping on new websites</li>
                            <li>Maintenance and updates to older sites</li>
                            <li>CMS (Lísa)</li>
                        </ul>
                    </TabPanel>
                </div>
            </Container>
        </section>
    )
}

export default Experience
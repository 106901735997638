import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'react-scroll'

//import Fade from '@material-ui/core/Fade'
import { StaggeredMotion, Motion, spring } from 'react-motion'

import NavItem from './navItem'


/**
* -----------------------------------
* Play with these values
* -----------------------------------
*/
const startY = -100;
const startOpacity = 0;

// Lower damping and stiffness here will exaggerate the 
// Start of the sequence of animations
const initialStiffness = 400;
const initialDamping = 40;

// Lower damping and stiffness here will exaggerate the 
// End of the sequence of animations
const finalStiffness = 400;
const finalDamping = 40;
/* ---------------------------------- */

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    navLinks: {
        '& > ul': {
            display: 'flex',
            alignItems: 'center',
            listStyle: 'none',
            margin: 0,
            padding: 0,
            '& > li': {
                margin: theme.spacing(2),
                '& > div': {
                    display: 'inline-block'
                }
            }
        }
    },
    logo: {
        '& a': {
            transition: 'all 150ms ease-in',
            cursor: 'pointer',
            '&:hover': {
                color: theme.palette.secondary.main,
            },
        },
        
    }
}))

let navItems = []

const Navigation = () => {

    const { allNavigationJson } = useStaticQuery(
        graphql`
            query {
                allNavigationJson {
                    edges {
                        node {
                            id
                            title
                        }
                    }
                }
            }
        `
    )

    const classes = useStyles()

    if(navItems.length === 0) {
        for(let i = 1; i < allNavigationJson.edges.length; i++) {
            navItems.push(allNavigationJson.edges[i])
        }
    }
    
    if(navItems && navItems.length) {
        return (
            <nav className={classes.root} role="navigation">
                <div className={classes.logo}>
                    <Motion
                        defaultStyle={{ y: startY, o: startOpacity }}
                        style={{ y: spring(0), o: spring(1) }}>
                        {styles => 
                            <div style={{ transform: `translateY(${styles.y}px)`, opacity: styles.o }}>
                                <Link to="home" spy={true} smooth={true} offset={-70} duration={500}>Jóhannes Freyr</Link>
                            </div>
                        }
                    </Motion>
                </div>
                <div className={classes.navLinks}>
                    <ul>
                        <StaggeredMotion
                            defaultStyles={navItems.map((item, index) => {
                                return { y: startY, o: startOpacity }
                            })}
                            styles={prevInterpolatedStyles => prevInterpolatedStyles.map((_, i) => {
                                return i === 0
                                    // Initial stiffness and damping
                                    ? { y: spring(0, { stiffness: initialStiffness, damping: initialDamping }), o: spring(1) }
                                    // Final stiffness and damping 
                                    : {
                                        y: spring(prevInterpolatedStyles[i - 1].y, { stiffness: finalStiffness, damping: finalDamping }),
                                        o: spring(prevInterpolatedStyles[i - 1].o)
                                    }
                            })}>
                            {interpolatingStyles => <>
                                {
                                    interpolatingStyles.map((style, i) => {
                                        const navStyles = {
                                            WebkitTransform: `translate3d(0, ${style.y}px, 0)`,
                                            opacity: style.o
                                        }
                                        const navItem = navItems[i].node
    
                                        return <li style={navStyles} key={navItem.id + Math.random()}>
                                            <NavItem item={navItem} index={i} style={navStyles} />
                                        </li>
                                    })
                                } </>
                            }
                        </StaggeredMotion>
                    </ul>
                </div>
    
            </nav>
        )
    } else {
        return <></>
    }
    
}

export default Navigation
import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Button, Container } from '@material-ui/core'

import SectionHeader from './sectionHeader'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        padding: "100px 0 0 0",
        minHeight: '70vh',
        [theme.breakpoints.up('md')]: {
            padding: "150px 100px",
            minHeight: 0
        }
    },
    footer: {
        padding: `${theme.spacing(2)}px 0`,
        textAlign: 'center',
        fontFamily: 'Share Tech Mono',
        opacity: '0.7'
    }
}))

const StyledButton = withStyles(theme => ({
    root: {
        marginTop: theme.spacing(4),
        textTransform: 'none',
        fontWeight: '300',
        border: `1px solid ${theme.palette.secondary.main}`,
        fontFamily: 'Share Tech Mono'
    }
}))(Button)

const Contact = () => {

    const classes = useStyles()

    return (
        <>
            <section id="contact" className={classes.root}>
                <Container maxWidth="sm">
                    <SectionHeader title="Let's talk" variant="h3" index={3} line={false} centered={true} />

                    <Typography paragraph>
                        I'm open for job opportunities at the moment. Weather you have a question or just want to say hi, I'll get back back to you as soon as I can.
                    </Typography>

                    <StyledButton href="mailto:johannesfreyr@gmail.com" color="secondary" size="large" variant="contained">Get in touch!</StyledButton>
                </Container>
            </section>

            <footer className={classes.footer}>
                <div>Designed & built by Jóhannes Freyr Þorleifsson</div>
            </footer>
        </>
    )
}

export default Contact